import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { DateTime } from "luxon";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Button, ButtonGroup, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { getAllModels, selectAllModels } from '../../../store/modelSlice';
import { clearCategoriesList, clearQuestionsList, getCategoriesList, getQuestionsList, selectCategoriesList, selectQuestionsList } from '../../../store/qasSlice';
import { addImportCorpusName, addRows, clearCorpusDataForJoin, editCorpusData, getCorpus, getCorpusForJoin, getCorpusList, importCorpusFile, importCorpusFromModel, putCorpus, selectCorpus, selectCorpusForJoin, selectCorpusImportStatus, selectCorpusList, selectCorpusPutStatus } from '../../../store/corpusSlice';
import useAccessRight from '../../../hooks/useAccessRight';
import useTranslate from '../../../hooks/useTranslate';
import { MODEL, QAS } from '../../../constants/accessRights';
import { ICorpusData } from '../../../types/corpusTypes';
import { RequestStatus, ResponseStatus } from '../../../types/statusTypes';
import ModalFormWindow from '../../../HOC/ModalFormWindow/ModalFormWindow';
import ProgressCircle from '../../ProgressCircle/ProgressCircle';
import ProgressLinear from '../../ProgressLinear/ProgressLinear';
import { IFormImportCorpusProps } from './FormImportCorpus.props';
import styles from './FormImportCorpus.module.scss';

const FormImportCorpus = ({ showModal, setShowModal, create = false, name, serviceType, setShowNotification, setChangeFlg }: IFormImportCorpusProps): JSX.Element => {
	const now = DateTime.now();
	const [corpusAdditionType, setCorpusAdditionType] = useState<'file' | 'model' | 'corpus' | 'qas'>('file'); // выбор, откуда будет импортироваться корпус
	const [nameInput, setNameInput] = useState<string>(''); // имя нового корпуса
	const [file, setFile] = useState<File>(); // для импорта из файла
	const [startDate, setStartDate] = useState<string>(now.minus({ week: 1 }).toISODate() ?? ''); // начальная дата для импорта из модели (минус 1 неделя от текущего дня)
	const [stopDate, setStopDate] = useState<string>(now.toISODate() ?? ''); // конечная дата для импорта из модели (сегодня)
	const [modelList, setModelList] = useState<string[]>([]); // список моделей
	const [modelName, setModelName] = useState<string>(''); // имя модели, из которой будет импортироваться корпус
	const [corpusName, setCorpusName] = useState<string>(''); // имя корпуса, который будет импортироваться в корпус
	const [limit, setLimit] = useState<number>(1000); // лимит для импорта из модели
	const [noDataFlg, setNoDataFlg] = useState<boolean>(false); // флаг для обозначения отсутствия данных
	const [existCorpusFlg, setExistCorpusFlg] = useState<boolean>(false); // флаг для уведомления о существующем корпусе
	const [categoryDictionary, setCategoryDictionary] = useState<Record<string, string>>({}); // словарь категорий

	const dispatch = useAppDispatch();
	const allModels = useAppSelector(selectAllModels); // store - все модели
	const corpusList = useAppSelector(selectCorpusList); // store - список корпусов
	const questionsList = useAppSelector(selectQuestionsList); // store - список вопросов
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const importStatusCorpus = useAppSelector(selectCorpusImportStatus); // store - статус импортирования корпуса
	const сorpusPutStatus = useAppSelector(selectCorpusPutStatus); // store - статус создания пустого корпуса
	const corpus = useAppSelector(selectCorpus); // store - корпус
	const corpusForJoin = useAppSelector(selectCorpusForJoin); // store - корпус для склеивания

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за кнопкой выбора откуда будет импортироваться корпус
	useEffect(() => {
		corpusAdditionType === 'model' && dispatch(getAllModels({ serviceType })); // если импорт из модели - получаем все модели
		// если импорт из ЧАВО
		if (corpusAdditionType === 'qas') {
			dispatch(getQuestionsList({})); // получаем все вопросы
			isAccess(QAS.CATEGORY_LIST) && dispatch(getCategoriesList()); // получаем список категорий
		}
		dispatch(clearCorpusDataForJoin()); // очищаем данные корпуса для склеивания
		setCorpusName('');
	}, [corpusAdditionType]);

	// следим за именем корпуса, выбранного из существующих
	useEffect(() => {
		if (corpusName !== '') {
			dispatch(clearCorpusDataForJoin()); // очищаем данные корпуса
			dispatch(getCorpusForJoin({ corpusName, serviceType })); // получения корпуса для склеивания
		}
	}, [corpusName]);

	// следим за статусом импортирования корпуса
	useEffect(() => {
		// если корпус создавался и без ошибок - получаем заново список корпусов
		if (create) {
			((importStatusCorpus.error === ResponseStatus.SUCCESS && importStatusCorpus.status === RequestStatus.IDLE && importStatusCorpus.message !== '') || (сorpusPutStatus.error === ResponseStatus.SUCCESS && сorpusPutStatus.status === RequestStatus.IDLE && сorpusPutStatus.message !== '')) && dispatch(getCorpusList({ serviceType }));
			// иначе обновляем информацию по корпусу
		} else {
			name && importStatusCorpus.error === ResponseStatus.SUCCESS && importStatusCorpus.status === RequestStatus.IDLE && importStatusCorpus.message !== '' && dispatch(getCorpus({ corpusName: name, serviceType }));
		}
		// если изменился статус импортирования
		if (importStatusCorpus.error !== ResponseStatus.SUCCESS || importStatusCorpus.status === RequestStatus.FAILED || importStatusCorpus.message !== '' || сorpusPutStatus.error !== ResponseStatus.SUCCESS || сorpusPutStatus.status === RequestStatus.FAILED || сorpusPutStatus.message !== '') {
			handleClose(); // закрываем форму
			setShowNotification && setShowNotification(true); // включаем уведомление
		}
	}, [importStatusCorpus, сorpusPutStatus]);

	// следим за полученными моделями
	useEffect(() => {
		corpusAdditionType === 'model' && allModels.models && typeof (allModels.models) !== 'string' && setModelList(Object.keys(allModels.models)); // если импорт из модели - записываем в state список моделей
	}, [allModels.models]);

	// следим за данными списка категорий и пишем словарь "id: name"
	useEffect(() => {
		if (categoriesList.data.length > 0) {
			const categoryObj: Record<string, string> = {};
			categoriesList.data.forEach(categoryItem => categoryObj[categoryItem.id] = categoryItem.name);
			setCategoryDictionary(categoryObj);
		}
	}, [categoriesList.data]);

	// обработчик закрытия формы
	const handleClose = (): void => {
		// если идет создание или импорт корпуса - запрещаем покидать форму
		if (importStatusCorpus.status === RequestStatus.LOADING || сorpusPutStatus.status === RequestStatus.LOADING) return;
		setShowModal(false); // закрытие модального окна
		corpusForJoin.data.data.length > 0 && dispatch(clearCorpusDataForJoin()); // если был корпус для склеивания - очищаем его
		questionsList.data.length > 0 && dispatch(clearQuestionsList()); // если были загружены вопросы - очищаем
		categoriesList.data.length > 0 && dispatch(clearCategoriesList()); // если были загружены категории - очищаем
	};

	// функция поиска уникальных строк
	const searchUniqueRows = (e: FormEvent<HTMLFormElement>, newCorpusData: ICorpusData): void => {
		// если была нажата кнопка "Дополнить"
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'additionBtn') {
			let noUniqueData = true;
			// проходим по каждому ключу корпуса данных
			for (const keyofCorpusData in newCorpusData) {
				const corpusDataToString = corpus.data[keyofCorpusData as keyof ICorpusData].map(row => [row[0], row[1].replace(/,/g, '~~$')].toString()); // преобразуем корпус в строку с временной заменой запятых во фразе
				const newCorpusDataToString = newCorpusData[keyofCorpusData as keyof ICorpusData].map(row => [row[0].map(className => className.trim()), row[1].replace(/,/g, '~~$')].toString()); // преобразуем новые данные (с удалением лишних пробелов в классах) в строку с временной заменой запятых во фразе
				// пропускаем совпадения новых данных с корпусом данных и преобразуем обратно в массив с заменой временных символов на запятые
				const uniqueRows: [string[], string][] = newCorpusDataToString
					.filter(row => !corpusDataToString.includes(row))
					.map(row => {
						const result = row.split(',');
						const phrase = result.pop();
						return [result, String(phrase).replace(/~~\$/g, ',')];
					});
				// если были получены новые уникальные данные
				if (uniqueRows.length > 0) {
					noUniqueData = false;
					dispatch(addRows({ data: uniqueRows, type: keyofCorpusData as keyof ICorpusData })); // добавляем строки в store
					setChangeFlg && setChangeFlg(true); // меняем флаг на не сохраненные данные
				}
			}
			// если нет уникальных данных - ставим флаг на отсутствие данных, иначе закрываем форму
			noUniqueData ? setNoDataFlg(true) : handleClose();
		}
		// если была нажата кнопка "Заменить"
		if ((e.nativeEvent as SubmitEvent).submitter?.id === 'replacementBtn') {
			// перебираем данные и убираем пробелы в названии классов
			for (const keyofCorpusData in newCorpusData) {
				newCorpusData[keyofCorpusData as keyof ICorpusData] = newCorpusData[keyofCorpusData as keyof ICorpusData].map(row => [row[0].map(className => className.trim()), row[1]]);
			}
			dispatch(editCorpusData(newCorpusData)); // заменяем корпус в store
			setChangeFlg && setChangeFlg(true); // меняем флаг на не сохраненные данные
			handleClose(); // закрываем форму
		}
	};

	// обработчик импортирования корпуса
	const submitHandler = (e: FormEvent<HTMLFormElement>): void => {
		e.preventDefault();
		const formData = new FormData();
		if (file) {
			formData.append('file', file);
		}
		// если корпус создается
		if (create) {
			// .. из файла
			if (corpusAdditionType === 'file') {
				if (file) dispatch(importCorpusFile({ corpusName: nameInput, formData, serviceType })); // если есть файл - импортируем
				else {
					// если корпус уже существует - ставим флаг на существование такого корпуса и выходим
					if (corpusList.data.includes(nameInput)) {
						setExistCorpusFlg(true);
						return;
					}
					dispatch(putCorpus({ corpusName: nameInput, text: { classes: [], data: [], groups: [] }, serviceType })); // иначе создаем пустой
				}
			}
			// .. из модели - получаем данные из логов модели
			else if (corpusAdditionType === 'model') dispatch(importCorpusFromModel({ corpusName: nameInput, startDate, stopDate, modelName, limit, serviceType }));
			// .. из qas - получаем вопросы из qas, формируем файл и создаем корпус
			else if (corpusAdditionType === 'qas') {
				let str: string = '';
				const arrCategories: string[] = [];

				questionsList.data.forEach(questionItem => {
					if (questionItem.category) {
						if (!arrCategories.includes(questionItem.question)) {
							arrCategories.push(questionItem.question);
							str += ((categoryDictionary[questionItem.category]) || questionItem.category) + '\t' + questionItem.question + '\n';
						}
						questionItem.aliases.forEach(alias => {
							if (!arrCategories.includes(alias)) {
								arrCategories.push(alias);
								str += ((questionItem.category && categoryDictionary[questionItem.category]) || questionItem.category) + '\t' + alias + '\n';
							}
						});
					}
				});
				const conditionalFormData = new FormData();
				const documentFile = new File([str], 'corpus_from_questions_list.csv', {
					type: "text/csv",
				});
				conditionalFormData.append('file', documentFile);
				dispatch(importCorpusFile({ corpusName: nameInput, formData: conditionalFormData, serviceType })); // импортируем корпус
			}
			dispatch(addImportCorpusName(nameInput)); // добавляем в store имя импортируемого корпуса
		} else {
			// иначе если импортируется в существующий корпус из файла
			if (corpusAdditionType === 'file') {
				const reader = new FileReader();
				file && reader.readAsText(file); // читаем файл 
				reader.onload = () => {
					const matchNewCorpus = String(reader.result).match(/\S.*\t.+\t.+/g); // ищем совпадения по формату "Класс-TAB-Фраза-TAB-Тип" с не пустым классом (новые корпуса)
					// если есть совпадения и расширение файла .csv или .txt
					if (matchNewCorpus !== null && (file?.type === 'text/csv' || file?.type === 'text/plain' || file?.type === 'application/vnd.ms-excel')) {
						// разбиваем данные на массив по табуляции и разделяем классы по запятой
						const split: [string[], string, string][] = matchNewCorpus?.map(str => {
							const result = str.split('\t');
							return [result[0].trim().split(',').sort(), result[1].trim(), result[2].trim()];
						});
						const newCorpusDataFromFile: ICorpusData = {
							classes: [],
							data: [],
							groups: [],
						};
						// распределяем строки по типу
						split.forEach(elem => {
							switch (elem[2]) {
								case 'class':
									newCorpusDataFromFile.classes.push([elem[0], elem[1]]);
									return;
								case 'data':
									newCorpusDataFromFile.data.push([elem[0], elem[1]]);
									return;
								case 'group':
									newCorpusDataFromFile.groups.push([elem[0], elem[1]]);
									return;
								default:
									newCorpusDataFromFile.data.push([elem[0], elem[1]]);
									return;
							};
						});
						searchUniqueRows(e, newCorpusDataFromFile); // ищем уникальные строки
					} else {
						// иначе ищем совпадения по старому формату корпуса
						const matchOldCorpus = String(reader.result).match(/.+\t.+/g); // ищем совпадения по формату "Класс-TAB-Фраза" (старые корпуса)
						// если есть совпадения и расширение файла .csv или .txt
						if (matchOldCorpus !== null && (file?.type === 'text/csv' || file?.type === 'text/plain' || file?.type === 'application/vnd.ms-excel')) {
							// разбиваем данные на массив по табуляции и разделяем классы по запятой
							const split: [string[], string][] = matchOldCorpus?.map(str => {
								const result = str.split('\t');
								return [result[0].trim().split(',').sort(), result[1].trim()];
							});
							const newCorpusDataFromFile: ICorpusData = {
								classes: [],
								data: split,
								groups: [],
							};
							searchUniqueRows(e, newCorpusDataFromFile); // ищем уникальные строки
						} else setNoDataFlg(true); // иначе ставим флаг на отсутствие данных
					}
				};
				reader.onerror = () => console.error(reader.error); // ошибки по чтению из файла выводим в консоль
				// иначе если импортируется в существующий корпус из корпуса
			} else if (corpusAdditionType === 'corpus') {
				searchUniqueRows(e, corpusForJoin.data); // ищем уникальные строки
			}
		}
	};

	return (
		<ModalFormWindow
			showModal={showModal}
			setShowModal={setShowModal}
			headerTitle={create ? "formHeader_addingCorpus" : `${translate("formHeader_importCorpus")} "${name}"`}
			close={handleClose}
		>
			<>
				<form onSubmit={(e) => submitHandler(e)}>
					{create ?
						<>
							{serviceType === 'smc' && (isAccess(MODEL.INFO) || isAccess(QAS.QUESTION_LIST)) &&
								<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
									<Button variant={corpusAdditionType === 'file' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('file')}>{translate('button_fromFile')}</Button>
									{isAccess(MODEL.INFO) &&
										<Button variant={corpusAdditionType === 'model' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('model')}>{translate('button_fromModelLogs')}</Button>
									}
									{isAccess(QAS.QUESTION_LIST) &&
										<Button variant={corpusAdditionType === 'qas' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('qas')}>{translate('button_fromQas')}</Button>
									}
								</ButtonGroup>
							}
							<FormControl fullWidth margin='dense'>
								<TextField
									autoFocus
									required
									id="outlined-basic"
									label={translate("input_name")}
									variant="outlined"
									value={nameInput}
									onChange={(e) => { setNameInput(e.target.value); existCorpusFlg && setExistCorpusFlg(false); }}
									error={existCorpusFlg}
									helperText={existCorpusFlg ? translate("title_corpusExists") : ''}
									InputProps={{
										style: {
											height: 33,
											fontSize: 13
										},
									}}
									InputLabelProps={{
										style: {
											fontSize: 13,
										},
									}}
									sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
								/>
							</FormControl>
						</>
						:
						<ButtonGroup fullWidth sx={{ marginBottom: '8px' }}>
							<Button variant={corpusAdditionType === 'file' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('file')}>{translate('button_fromFile')}</Button>
							<Button variant={corpusAdditionType === 'corpus' ? "contained" : "outlined"} sx={{ width: '50%', overflow: 'hidden', fontSize: 11 }} onClick={() => setCorpusAdditionType('corpus')}>{translate('button_fromCorpus')}</Button>
						</ButtonGroup>
					}
					{corpusAdditionType === 'file' &&
						<FormControl fullWidth margin='dense'>
							<TextField
								id="outlined-basic"
								variant="outlined"
								type="file"
								onChange={(e: ChangeEvent<HTMLInputElement>) => { e.target.files && setFile(e.target.files[0]); setNoDataFlg(false); }}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13
									},
									inputProps: { accept: "text/csv, text/plain" }
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}
					{corpusAdditionType === 'model' &&
						<>
							{allModels.status !== RequestStatus.LOADING ?
								<>
									{modelList.length > 0 ?
										<>
											<div className={styles.inputsDate}>
												<TextField
													required
													id="outlined-basic"
													label={translate('input_start')}
													variant="outlined"
													type='date'
													value={startDate}
													onChange={(e) => setStartDate(e.target.value)}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13
														},
														inputProps: { max: now.toISODate() }
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: '50%' }}
												/>
												<TextField
													required
													id="outlined-basic"
													label={translate('input_end')}
													variant="outlined"
													type='date'
													value={stopDate}
													onChange={(e) => setStopDate(e.target.value)}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13
														},
														inputProps: { max: now.toISODate() }
													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, width: '50%' }}
												/>
											</div>
											<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}>
												<InputLabel id="model-label" sx={{ fontSize: 13 }}>{translate('select_model')}</InputLabel>
												<Select
													required
													labelId="model-label"
													id="model"
													label={translate('select_model')}
													value={modelName}
													onChange={(e) => setModelName(e.target.value)}
													style={{ fontSize: 13, height: 33 }}

												>
													{modelList.sort().map(modelName => <MenuItem key={modelName} value={modelName} sx={{ fontSize: 13 }}>{modelName}</MenuItem>)}
												</Select>
											</FormControl>
											<FormControl fullWidth margin='dense'>
												<TextField
													required
													id="outlined-basic"
													label={translate('input_limit')}
													variant="outlined"
													type='number'
													value={limit}
													onChange={(e) => setLimit(Math.round(Number(e.target.value)))}
													InputProps={{
														style: {
															height: 33,
															fontSize: 13
														},
														inputProps: { min: 0 }

													}}
													InputLabelProps={{
														style: {
															fontSize: 13,
														},
													}}
													sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
												/>
											</FormControl>
										</>
										:
										<Typography variant="caption">{translate('title_emptyList')}</Typography>
									}
								</>
								:
								<ProgressCircle title={translate('spinnerTitle_loading')} inLine />
							}
						</>
					}
					{corpusAdditionType === 'qas' &&
						<>
							{questionsList.status === RequestStatus.LOADING && <ProgressCircle title={translate('spinnerTitle_loading')} inLine />}
							{questionsList.data.length === 0 && questionsList.status !== RequestStatus.LOADING && <Typography variant="caption">{translate('title_emptyList')}</Typography>}
						</>
					}
					{corpusAdditionType === 'corpus' &&
						<>
							{corpusList.status === RequestStatus.IDLE ?
								<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }} error={corpusForJoin.status === RequestStatus.FAILED}>
									<InputLabel id="corpus-label" sx={{ fontSize: 13 }}>{translate('select_corpus')}</InputLabel>
									<Select
										required
										labelId="corpus-label"
										id="corpus"
										label={translate('select_corpus')}
										value={corpusName}
										onChange={(e) => setCorpusName(e.target.value)}
										style={{ fontSize: 13, height: 33 }}

									>
										{corpusList.data.map(corpusName => corpus.corpusName !== corpusName && <MenuItem key={corpusName} value={corpusName} sx={{ fontSize: 13 }}>{corpusName}</MenuItem>)}
									</Select>
									{corpusForJoin.status === RequestStatus.FAILED && <FormHelperText>{translate('title_loadFailed')}</FormHelperText>}
								</FormControl>
								:
								<ProgressCircle title={translate('spinnerTitle_loading')} />
							}
						</>
					}
					{(allModels.status === RequestStatus.IDLE) &&
						<>
							{(importStatusCorpus.status === RequestStatus.LOADING || сorpusPutStatus.status === RequestStatus.LOADING) ?
								<FormControl fullWidth margin='dense'>
									<Button variant="outlined" type="submit" disabled sx={{ fontSize: 11 }}>
										{translate(create ? 'button_add' : 'button_import')}
										<ProgressCircle isBtnDisabled />
									</Button>
								</FormControl>
								:
								<>
									{!create ?
										<>
											{corpusForJoin.status !== RequestStatus.LOADING ?
												<div className={styles.buttonsImportCorpus}>
													<Button variant="outlined" type='submit' id='additionBtn' sx={{ width: '33%', overflow: 'hidden', fontSize: 11 }} disabled={corpusForJoin.status === RequestStatus.FAILED}>
														{translate('button_addition')}
													</Button>
													<Button variant="outlined" type='submit' id='replacementBtn' sx={{ width: '33%', overflow: 'hidden', fontSize: 11 }} disabled={corpusForJoin.status === RequestStatus.FAILED}>
														{translate('button_replace')}
													</Button>
													<Button variant="outlined" onClick={handleClose} sx={{ width: '33%', overflow: 'hidden', fontSize: 11 }}>
														{translate('button_cancel')}
													</Button>
												</div>
												:
												<ProgressCircle title={translate('spinnerTitle_loading')} inLine />
											}
											{noDataFlg && <div className={styles.noData}>{translate('title_noData')}</div>}
										</>
										:
										<>
											{(corpusAdditionType === 'file' || (corpusAdditionType === 'model' && modelList.length > 0) || (corpusAdditionType === 'qas' && questionsList.data.length > 0 && questionsList.status !== RequestStatus.LOADING)) &&
												<FormControl fullWidth margin='dense'>
													<Button variant="outlined" type="submit" sx={{ fontSize: 11 }}>
														{translate(create ? 'button_add' : 'button_import')}
													</Button>
												</FormControl>
											}
										</>
									}
								</>
							}
						</>
					}
				</form>
				{create && corpusAdditionType === 'file' && importStatusCorpus.status === RequestStatus.LOADING &&
					<ProgressLinear value={importStatusCorpus.progress} />
				}
			</>
		</ModalFormWindow>
	);
};

export default FormImportCorpus;
