import { ChangeEvent, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { addCategoryDataElem, addCategoryDataEndpoint, addCategoryDataEndpointEntry, addConditionRunActionDataElem, addConditionRunActionEndpoint, addConditionRunActionEndpointEntry, addPhraseInActionDataElem, addPhraseInActionEndpoint, addPhraseInActionEndpointEntry, changeActionDataElem, changeActionEndpoint, changeActionEndpointEntry, changeActionTypeDataElem, changeActionTypeEndpoint, changeActionTypeEndpointEntry, changeDestinationDataElem, changeDestinationEndpoint, changeDestinationEndpointEntry, changeScriptDataElem, changeScriptEndpoint, changeScriptEndpointEntry, changeServiceDataElem, changeServiceEndpoint, changeServiceEndpointEntry, changeSetAnswered, deleteActionDataElem, deleteActionEndpoint, deleteActionEndpointEntry, } from '../../store/sesSlice';
import { addCategoryDataException, addCategoryDataRobot, addConditionRunActionExceptionRobot, addConditionRunActionRobot, addPhraseInActionRobot, addPhraseInException, changeActionException, changeActionRobot, changeActionTypeException, changeActionTypeRobot, changeDestinationException, changeDestinationRobot, changeScriptException, changeScriptRobot, changeServiceException, changeServiceRobot, deleteActionRobot, deleteRobotException, selectActiveRobotVersion } from '../../store/sesRobotSlice';
import { selectScriptList } from '../../store/sesScriptSlice';
import { selectCategoriesList } from '../../store/qasSlice';
import useAccessRight from '../../hooks/useAccessRight';
import useTranslate from '../../hooks/useTranslate';
import { SES } from '../../constants/accessRights';
import { ACTION_LIST, ACTION_TYPE_LIST, SERVICE_TYPE_LIST } from '../../constants/robotConfigLists';
import { colorPrimary, colorRed, colorSecondaryLight3 } from '../../constants/colors';
import { Action, ActionType, ActionVariantType, IActionInternalAsk, IActionInternalChat, ServiceType } from '../../types/sesTypes';
import { CachingType } from '../../types/qasTypes';
import Phrase from './Phrase/Phrase';
import ConditionRunBlock from '../ConditionRunBlock/ConditionRunBlock';
import FormAddingCategoryData from '../Forms/FormAddingCategoryData/FormAddingCategoryData';
import FormAddingConditionRunAction from '../Forms/FormAddingConditionRunAction/FormAddingConditionRunAction';
import { IActionProps } from './ActionEvent.props';
import styles from './ActionEvent.module.scss';

const ActionEvent = ({ action, idx, channel, changeFlg, setChangeFlg, actionFor }: IActionProps): JSX.Element => {
	const [selectAction, setSelectAction] = useState<Action>(action.action); // действие
	const [selectActionType, setSelectActionType] = useState<ActionType>(action.action === 'say' ? action.type : 'linear'); // тип действия
	const [checkSetAnswered, setCheckSetAnswered] = useState<boolean>('setAnswered' in action && action.setAnswered ? action.setAnswered : false); // флаг "считать действие успешным прохождением точки" (только для действий КТ)
	const [script, setScript] = useState<string>(action.action === 'say' && action.type === 'external' ? action.script : ''); // скрипт
	const [service, setService] = useState<ServiceType>(action.action === 'say' && action.type === 'internal' ? action.service : 'qas-ask'); // сервис
	const [selectCategoryId, setSelectCategoryId] = useState<string>((action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && Object.keys(action.categories || {})[0]) || ''); // id категории
	const [selectCaching, setSelectCaching] = useState<CachingType>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' ? action.useCache : 'checked'); // кеширование
	const [checkGPT, setCheckGPT] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.useGPT === 'yes' ? true : false); // использование gpt
	const [checkCreative, setCheckCreative] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.creative === 'yes' ? true : false); // использование творческого режима
	const [addAlias, setAddAlias] = useState<boolean>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-ask' && action.addAlias === 'yes' ? true : false); // добавление вопроса в похожие в кэше, если похожая формулировка найдена
	const [depth, setDepth] = useState<number>(action.action === 'say' && action.type === 'internal' && action.service === 'qas-chat' ? action.depth : 3); // глубина истории сообщений
	const [destination, setDestination] = useState<string>(action.action === 'transfer' ? action.destination : ''); // место назначения

	const [showCategoriesSettings, setShowCategoriesSettings] = useState<boolean>(false); // показ настроек категорий
	const [showAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(false); // показ доп.настроек (условий)

	const dispatch = useAppDispatch();
	const categoriesList = useAppSelector(selectCategoriesList); // store - список категорий
	const scriptList = useAppSelector(selectScriptList); // store - список скриптов
	const activeRobotVersion = useAppSelector(selectActiveRobotVersion); // store - версия активного робота

	const isAccess = useAccessRight(); // hook для проверки прав доступа
	const translate = useTranslate(); // hook для перевода текста

	// следим за действием
	useEffect(() => {
		// если касается действий с фразами
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			// есть права на редактирование и последняя фраза не пустая - добавляем новую
			(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) ||
				(actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) ||
				((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))
			) && action.text[action.text.length - 1] !== '' && activeRobotVersion === 'draft' && addPhraseHandler();
		}
	}, [action]);

	// обработчик изменения действия
	const changeActionHandler = (e: SelectChangeEvent<Action>): void => {
		setSelectAction(e.target.value as Action);
		if (actionFor.for === 'robotException') {
			dispatch(changeActionException({ exception: actionFor.exception, channel, idx, action: e.target.value as Action })); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeActionEndpointEntry({ channel, idx, action: e.target.value as Action })); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeActionRobot({ actionEvent: actionFor.event, channel, idx, action: e.target.value as Action })); // робота
			actionFor.for === 'dataElement' && dispatch(changeActionDataElem({ actionEvent: actionFor.event, channel, idx, action: e.target.value as Action })); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeActionEndpoint({ channel, idx, action: e.target.value as Action })); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения типа действия
	const changeActionTypeHandler = (e: SelectChangeEvent<ActionType>): void => {
		setSelectActionType(e.target.value as ActionType);
		if (actionFor.for === 'robotException') {
			dispatch(changeActionTypeException({
				exception: actionFor.exception,
				channel,
				idx,
				action: selectAction,
				actionType: e.target.value as ActionType,
				text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeActionTypeEndpointEntry({
				channel,
				idx,
				action: selectAction,
				actionType: e.target.value as ActionType,
				text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeActionTypeRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				action: selectAction,
				actionType: e.target.value as ActionType,
				text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeActionTypeDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				action: selectAction,
				actionType: e.target.value as ActionType,
				text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeActionTypeEndpoint({
				channel,
				idx,
				action: selectAction,
				actionType: e.target.value as ActionType,
				text: (action.action === 'say' && (action.type === 'linear' || action.type === 'random') && (e.target.value === 'linear' || e.target.value === 'random')) ? action.text : undefined,
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик удаления действия
	const deleteActionHandler = (): void => {
		if (actionFor.for === 'robotException') {
			dispatch(deleteRobotException({ exception: actionFor.exception, channel, idx })); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(deleteActionEndpointEntry({ channel, idx })); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(deleteActionRobot({ actionEvent: actionFor.event, channel, idx })); // события робота
			actionFor.for === 'dataElement' && dispatch(deleteActionDataElem({ actionEvent: actionFor.event, channel, idx })); // ЭД
			actionFor.for === 'endpoint' && dispatch(deleteActionEndpoint({ channel, idx })); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения флага "считать действие успешным прохождением точки" (только для действий КТ)
	const changeSetAnsweredHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		setCheckSetAnswered(e.target.checked);
		actionFor.for === 'endpoint' && dispatch(changeSetAnswered({
			channel,
			idx,
			flag: e.target.checked,
		})); // КТ
		(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
	};

	// обработчик добавления фразы
	const addPhraseHandler = (): void => {
		if (action.action === 'say' && (action.type === 'linear' || action.type === 'random')) {
			if (actionFor.for === 'robotException') {
				dispatch(addPhraseInException({
					exception: actionFor.exception, channel, idx, actionObj: {
						action: action.action,
						type: action.type,
						text: [...action.text, ''],
						conditions: action.conditions,
						setAnswered: action.setAnswered,
					}
				})); // перезапись действия исключения робота с добавлением фразы
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
			} else if (actionFor.for === 'endpointEntry') {
				dispatch(addPhraseInActionEndpointEntry({
					channel, idx, actionObj: {
						action: action.action,
						type: action.type,
						text: [...action.text, ''],
						conditions: action.conditions,
						setAnswered: action.setAnswered,
					}
				})); // перезапись действия входа КТ с добавлением фразы
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
			} else {
				actionFor.for === 'robotEvent' && dispatch(addPhraseInActionRobot({
					actionEvent: actionFor.event, channel, idx, actionObj: {
						action: action.action,
						type: action.type,
						text: [...action.text, ''],
						conditions: action.conditions,
						setAnswered: action.setAnswered,
					}
				})); // перезапись действия события робота с добавлением фразы
				actionFor.for === 'dataElement' && dispatch(addPhraseInActionDataElem({
					actionEvent: actionFor.event, channel, idx, actionObj: {
						action: action.action,
						type: action.type,
						text: [...action.text, ''],
						conditions: action.conditions,
						setAnswered: action.setAnswered,
					}
				})); // перезапись действия ЭД с добавлением фразы
				actionFor.for === 'endpoint' && dispatch(addPhraseInActionEndpoint({
					channel, idx, actionObj: {
						action: action.action,
						type: action.type,
						text: [...action.text, ''],
						conditions: action.conditions,
						setAnswered: action.setAnswered,
					}
				})); // перезапись действия КТ с добавлением фразы
				(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
			}
		}
	};

	// обработчик изменения скрипта
	const changeScriptHandler = (e: SelectChangeEvent<string>): void => {
		setScript(e.target.value);
		if (actionFor.for === 'robotException') {
			dispatch(changeScriptException({
				exception: actionFor.exception,
				channel,
				idx,
				script: e.target.value,
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeScriptEndpointEntry({
				channel,
				idx,
				script: e.target.value,
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeScriptRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				script: e.target.value,
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeScriptDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				script: e.target.value,
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeScriptEndpoint({
				channel,
				idx,
				script: e.target.value,
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения сервиса
	const changeServiceHandler = (e: SelectChangeEvent<ServiceType>): void => {
		const getPayloadData = (service: ServiceType): { service: Extract<ServiceType, 'qas-ask'>, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' } | { service: Extract<ServiceType, 'qas-chat'>, depth: number } => {
			if (service === 'qas-ask') return {
				service,
				useCache: selectCaching,
				useGPT: checkGPT ? 'yes' : 'no',
				creative: checkCreative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
			};
			else return {
				service,
				depth,
			};
		};
		setService(e.target.value as ServiceType);
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				...getPayloadData(e.target.value as ServiceType),
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				...getPayloadData(e.target.value as ServiceType),
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.value as ServiceType),
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.value as ServiceType),
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				...getPayloadData(e.target.value as ServiceType),
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик добавления данных категории
	const addCategoryDataHandler = (categoryBlockIdx: number): void => {
		if (actionFor.for === 'robotException') {
			dispatch(addCategoryDataException({
				exception: actionFor.exception,
				channel,
				channelIdx: idx,
				categoryId: selectCategoryId,
				categoryBlockIdx,
				data: { type: 'data', depth: 5, id: '', operation: 'exists', value: '' },
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addCategoryDataEndpointEntry({
				channel,
				channelIdx: idx,
				categoryId: selectCategoryId,
				categoryBlockIdx,
				data: { type: 'data', depth: 5, id: '', operation: 'exists', value: '' },
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addCategoryDataRobot({
				actionEvent: actionFor.event,
				channel,
				channelIdx: idx,
				categoryId: selectCategoryId,
				categoryBlockIdx,
				data: { type: 'data', depth: 5, id: '', operation: 'exists', value: '' },
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(addCategoryDataElem({
				actionEvent: actionFor.event,
				channel,
				channelIdx: idx,
				categoryId: selectCategoryId,
				categoryBlockIdx,
				data: { type: 'data', depth: 5, id: '', operation: 'exists', value: '' },
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(addCategoryDataEndpoint({
				channel,
				channelIdx: idx,
				categoryId: selectCategoryId,
				categoryBlockIdx,
				data: { type: 'data', depth: 5, id: '', operation: 'exists', value: '' },
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования кэша ответов
	const changeCachingHandler = (e: SelectChangeEvent<CachingType>): void => {
		const getPayloadData = (useCache: CachingType): { service: Extract<ServiceType, 'qas-ask'>, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' } | { service: Extract<ServiceType, 'qas-chat'>, depth: number } => {
			if (service === 'qas-ask') return {
				service,
				useCache,
				useGPT: checkGPT ? 'yes' : 'no',
				creative: checkCreative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
			};
			else return {
				service,
				depth,
			};
		};
		setSelectCaching(e.target.value as CachingType);
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				...getPayloadData(e.target.value as CachingType),
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				...getPayloadData(e.target.value as CachingType),
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.value as CachingType),
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.value as CachingType),
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				...getPayloadData(e.target.value as CachingType),
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования генерации
	const changeGPTHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const getPayloadData = (useGPT: boolean): { service: Extract<ServiceType, 'qas-ask'>, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' } | { service: Extract<ServiceType, 'qas-chat'>, depth: number } => {
			if (service === 'qas-ask') return {
				service,
				useCache: selectCaching,
				useGPT: useGPT ? 'yes' : 'no',
				creative: checkCreative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
			};
			else return {
				service,
				depth,
			};
		};
		setCheckGPT(e.target.checked);
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования творческого режима
	const changeCreativeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const getPayloadData = (creative: boolean): { service: Extract<ServiceType, 'qas-ask'>, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' } | { service: Extract<ServiceType, 'qas-chat'>, depth: number } => {
			if (service === 'qas-ask') return {
				service,
				useCache: selectCaching,
				useGPT: checkGPT ? 'yes' : 'no',
				creative: creative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
			};
			else return {
				service,
				depth,
			};
		};
		setCheckCreative(e.target.checked);
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения использования кэширования алиасов
	const changeAddAliasHandler = (e: ChangeEvent<HTMLInputElement>): void => {
		const getPayloadData = (addAlias: boolean): { service: Extract<ServiceType, 'qas-ask'>, useCache: CachingType, useGPT: 'yes' | 'no', creative: 'yes' | 'no', addAlias: 'yes' | 'no' } | { service: Extract<ServiceType, 'qas-chat'>, depth: number } => {
			if (service === 'qas-ask') return {
				service,
				useCache: selectCaching,
				useGPT: checkGPT ? 'yes' : 'no',
				creative: checkCreative ? 'yes' : 'no',
				addAlias: addAlias ? 'yes' : 'no',
			};
			else return {
				service,
				depth,
			};
		};
		setAddAlias(e.target.checked);
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				...getPayloadData(e.target.checked),
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения глубины истории сообщений
	const changeDepthHandler = (): void => {
		if (action.action === 'say' && action.type === 'internal' && action.service === 'qas-chat' && action.depth === depth) return; // выходим, если не поменялось значение
		if (actionFor.for === 'robotException') {
			dispatch(changeServiceException({
				exception: actionFor.exception,
				channel,
				idx,
				service: 'qas-chat',
				depth,
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeServiceEndpointEntry({
				channel,
				idx,
				service: 'qas-chat',
				depth,
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeServiceRobot({
				actionEvent: actionFor.event,
				channel,
				idx,
				service: 'qas-chat',
				depth,
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeServiceDataElem({
				actionEvent: actionFor.event,
				channel,
				idx,
				service: 'qas-chat',
				depth,
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeServiceEndpoint({
				channel,
				idx,
				service: 'qas-chat',
				depth,
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик изменения места назначения
	const changeDestinationHandler = (): void => {
		if (action.action === 'transfer' && action.destination === destination) return; // выходим, если не поменялось значение
		if (actionFor.for === 'robotException') {
			dispatch(changeDestinationException({
				exception: actionFor.exception,
				channel,
				idxAction: idx,
				destination,
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(changeDestinationEndpointEntry({
				channel,
				idxAction: idx,
				destination,
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(changeDestinationRobot({
				actionEvent: actionFor.event,
				channel,
				idxAction: idx,
				destination,
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(changeDestinationDataElem({
				actionEvent: actionFor.event,
				channel,
				idxAction: idx,
				destination,
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(changeDestinationEndpoint({
				channel,
				idxAction: idx,
				destination,
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	// обработчик добавления условия запуска действия
	const addConditionRunActionHandler = (conditionBlockIdx: number): void => {
		if (actionFor.for === 'robotException') {
			dispatch(addConditionRunActionExceptionRobot({
				exception: actionFor.exception,
				channel,
				channelIdx: idx,
				conditionBlockIdx,
				data: {
					type: 'interval',
					id: '',
				},
			})); // исключения робота
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('exceptions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'exceptions'] }));  // ставим флаг о несохраненных данных
		} else if (actionFor.for === 'endpointEntry') {
			dispatch(addConditionRunActionEndpointEntry({
				channel,
				channelIdx: idx,
				conditionBlockIdx,
				data: {
					type: 'interval',
					id: '',
				},
			})); // входа КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('entry')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'entry'] }));  // ставим флаг о несохраненных данных
		} else {
			actionFor.for === 'robotEvent' && dispatch(addConditionRunActionRobot({
				actionEvent: actionFor.event,
				channel,
				channelIdx: idx,
				conditionBlockIdx,
				data: {
					type: 'interval',
					id: '',
				},
			})); // события робота
			actionFor.for === 'dataElement' && dispatch(addConditionRunActionDataElem({
				actionEvent: actionFor.event,
				channel,
				channelIdx: idx,
				conditionBlockIdx,
				data: {
					type: 'interval',
					id: '',
				},
			})); // ЭД
			actionFor.for === 'endpoint' && dispatch(addConditionRunActionEndpoint({
				channel,
				channelIdx: idx,
				conditionBlockIdx,
				data: {
					type: 'interval',
					id: '',
				},
			})); // КТ
			(!changeFlg.thisIs || !changeFlg.listOfChanges.includes('actions')) && setChangeFlg(prev => ({ thisIs: true, listOfChanges: [...prev.listOfChanges, 'actions'] }));  // ставим флаг о несохраненных данных
		}
	};

	return (
		<div className={styles.container}>
			<div className={styles.actionBlock}>
				{/* действие */}
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_action')}</InputLabel>
					<Select
						required
						label={translate('select_action')}
						disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={selectAction}
						onChange={changeActionHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{ACTION_LIST.map(({ action, translation }) =>
							<MenuItem key={action} value={action} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
						)}
					</Select>
				</FormControl>

				{/* тип действия */}
				{selectAction === 'say' &&
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_actionType')}</InputLabel>
						<Select
							required
							disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							label={translate('select_actionType')}
							value={selectActionType}
							onChange={changeActionTypeHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{ACTION_TYPE_LIST.map(({ actionType, translation }) =>
								<MenuItem key={actionType}
									value={actionType}
									disabled={(actionType === 'external' && !isAccess(SES.SCRIPT_LIST)) || (actionType === 'internal' && actionFor.for === 'dataElement')}
									sx={{ fontSize: 13, color: colorPrimary }}
								>
									{translate(translation)}
								</MenuItem>
							)}
						</Select>
					</FormControl>
				}

				{/* удаление действия */}
				{(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
					<FontAwesomeIcon
						icon={faTrashCan}
						color={colorRed}
						size='lg'
						onClick={deleteActionHandler}
						title={translate('buttonTitle_deleteAction')}
						style={{ cursor: 'pointer' }}
					/>
				}
			</div>

			{/* передача диалога */}
			{action.action === 'transfer' &&
				<FormControl margin='dense'>
					<TextField
						label={translate('input_destination')}
						variant="outlined"
						value={destination}
						onChange={(e) => setDestination(e.target.value)}
						onBlur={changeDestinationHandler}
						disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						InputProps={{
							style: {
								height: 33,
								fontSize: 13,
								color: colorPrimary,
							},
						}}
						InputLabelProps={{
							style: {
								fontSize: 13,
							},
						}}
						sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
					/>
				</FormControl>
			}

			{/* считать действие успешным прохождением точки (только для действий КТ) */}
			{actionFor.for === 'endpoint' && service !== 'qas-chat' &&
				<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', marginBottom: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
					<Checkbox
						checked={checkSetAnswered}
						disabled={!isAccess(SES.ENDPOINT_EDIT) || activeRobotVersion !== 'draft'}
						onChange={changeSetAnsweredHandler}
						size='small'
					/>
				} label={translate('checkbox_considerActionSuccessfulPassingEndpoint')} />
			}

			{/* фразы */}
			{action.action === 'say' && (action.type === 'linear' || action.type === 'random') &&
				action.text.map((phrase, idxPhrase, arrayPhrases) => (
					<Phrase
						key={phrase + idxPhrase}
						phrase={phrase}
						idxPhrase={idxPhrase}
						arrayPhrases={arrayPhrases}
						channel={channel}
						idxAction={idx}
						changeFlg={changeFlg}
						setChangeFlg={setChangeFlg}
						actionFor={actionFor}
					/>
				))
			}

			{/* скрипт */}
			{selectActionType === 'external' && selectAction === 'say' &&
				<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
					<InputLabel sx={{ fontSize: 13 }}>{translate('select_script')}</InputLabel>
					<Select
						required
						label={translate('select_script')}
						disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
						value={script}
						onChange={changeScriptHandler}
						style={{ fontSize: 13, height: 33, color: colorPrimary }}
					>
						{/* на случай, если не найден скрипт из списка */}
						{!scriptList.data.find(scriptItem => scriptItem.id === script) &&
							<MenuItem value={script} sx={{ fontSize: 12, color: colorRed }}>{script} ({translate('selectItem_notFound')})</MenuItem>
						}
						{scriptList.data.map(({ id, name }) => (
							<MenuItem key={id} value={id} sx={{ fontSize: 13, color: colorPrimary }}>{name}</MenuItem>
						))}
					</Select>
				</FormControl>
			}

			{/* сервис */}
			{selectActionType === 'internal' && selectAction === 'say' &&
				<>
					{/* сервис */}
					<FormControl fullWidth margin='dense' sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 }, '.MuiSelect-select': { paddingBlock: 0 }, }}>
						<InputLabel sx={{ fontSize: 13 }}>{translate('select_service')}</InputLabel>
						<Select
							required
							label={translate('select_service')}
							disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							value={service}
							onChange={changeServiceHandler}
							style={{ fontSize: 13, height: 33, color: colorPrimary }}
						>
							{SERVICE_TYPE_LIST.map(({ serviceType, translation }) =>
								<MenuItem key={serviceType} value={serviceType} sx={{ fontSize: 13, color: colorPrimary }}>{translate(translation)}</MenuItem>
							)}
						</Select>
					</FormControl>

					{service === 'qas-ask' ?
						<div>
							<div className={styles.additionally} onClick={() => setShowCategoriesSettings(prev => !prev)}>
								{translate('link_conditionsCategories')}
							</div>

							{/* категория */}
							{showCategoriesSettings &&
								<>
									<FormControl fullWidth margin='dense' sx={{
										'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
										'.MuiSelect-select': { paddingBlock: 0 },
									}}>
										<InputLabel id="category-label" sx={{ fontSize: 13 }}>{translate('select_category')}</InputLabel>
										<Select
											labelId="category-label"
											id="category"
											label={translate('select_category')}
											value={selectCategoryId}
											onChange={e => setSelectCategoryId(e.target.value)}
											style={{ fontSize: 13, height: 33, color: colorPrimary, backgroundColor: colorSecondaryLight3 }}
										>
											{/* на случай, если не найдена категория */}
											{(Object.keys((action as IActionInternalAsk)?.categories || {}).map(categoryId => {
												if (!categoriesList.data.find(categoryItem => categoryItem.id === categoryId)) {
													return (
														<MenuItem key={categoryId} value={categoryId} sx={{ fontSize: 12, color: colorRed }}>{categoryId} ({translate('selectItem_notFound')})</MenuItem>
													);
												} else return undefined;
											}))}
											{categoriesList.data.map((category) => (
												<MenuItem key={category.id} value={category.id} sx={{ fontSize: 13, color: colorPrimary }}>
													{category.name} {(action as IActionInternalAsk).categories?.[category.id]?.length > 0 && `(${(action as IActionInternalAsk).categories[category.id].length})`}
												</MenuItem>
											))}
										</Select>
									</FormControl>

									{/* блоки условий присвоения категорий */}
									{((selectCategoryId !== '' && activeRobotVersion === 'draft') || (action as IActionInternalAsk).categories?.[selectCategoryId]?.length > 0) &&
										<>
											{(action as IActionInternalAsk).categories?.[selectCategoryId]?.map((categoryDataBlock, categoryBlockIdx, arrayCategoriesData) =>
												// блоки ИЛИ
												<ConditionRunBlock
													conditionDataBlock={categoryDataBlock}
													conditionBlockIdx={categoryBlockIdx}
													isDisable={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
													isAvailable={(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft'}
													changeFlg={changeFlg}
													setChangeFlg={setChangeFlg}
													conditionFor={{
														for: 'categoryAction',
														itsAction: actionFor.for,
														actionEvent: (actionFor.for === 'robotEvent' || actionFor.for === 'dataElement') ? actionFor.event : actionFor.for === 'robotException' ? actionFor.exception : undefined,
														channel,
														channelIdx: idx,
														categoryId: selectCategoryId,
													}}
													addConditionRunHandler={addCategoryDataHandler}
													arrayConditionsData={arrayCategoriesData}
													key={categoryDataBlock.toString() + categoryBlockIdx + selectCategoryId}
												/>
											)}
											{!(action as IActionInternalAsk).categories[selectCategoryId] || (action as IActionInternalAsk).categories?.[selectCategoryId]?.length === 0 ?
												(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
												// форма добавления условия
												<fieldset className={styles.categoryDataBlock}>
													<legend>{translate('title_conditions')}</legend>
													<FormAddingCategoryData
														channel={channel}
														channelIdx={idx}
														categoryId={selectCategoryId}
														categoryBlockIdx={(action as IActionInternalAsk).categories?.[selectCategoryId]?.length || 0}
														changeFlg={changeFlg}
														setChangeFlg={setChangeFlg}
														actionFor={actionFor}
													/>
												</fieldset>
												:
												(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
												// разделитель-кнопка ИЛИ
												<div className={styles.categoryDataBlockLineButton}>
													<span onClick={() => addCategoryDataHandler((action as IActionInternalAsk).categories[selectCategoryId]?.length || 0)}>{translate('title_or').toUpperCase()}</span>
												</div>
											}
										</>
									}
								</>
							}

							{/* кэш ответов */}
							<FormControl fullWidth margin='dense' sx={{
								'.MuiInputLabel-root[data-shrink="false"]': { top: -8 },
								'.MuiSelect-select': { paddingBlock: 0 },
							}}>
								<InputLabel id="caching-label" sx={{ fontSize: 13 }}>{translate('select_useAnswersCache')}</InputLabel>
								<Select
									labelId="caching-label"
									id="caching"
									label={translate('select_useAnswersCache')}
									disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
									value={selectCaching}
									onChange={changeCachingHandler}
									style={{ fontSize: 13, height: 33, color: colorPrimary }}
								>
									<MenuItem value='no' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_doNotUse')}</MenuItem>
									<MenuItem value='all' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_anyAnswers')}</MenuItem>
									<MenuItem value='checked' sx={{ fontSize: 13, color: colorPrimary }}>{translate('selectItem_onlyCheckedAnswers')}</MenuItem>
								</Select>
							</FormControl>

							<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-5px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={checkGPT}
									disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
									onChange={changeGPTHandler}
									size='small'
								/>
							} label={translate('checkbox_useGpt')} />
							<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-15px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px' } }} control={
								<Checkbox
									checked={checkCreative}
									disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
									onChange={changeCreativeHandler}
									size='small'
								/>
							} label={translate('checkbox_useCreative')} />
							<FormControlLabel sx={{ width: '100%', overflow: 'hidden', marginTop: '-3px', marginBottom: '-10px', '.MuiTypography-root': { fontSize: 13, marginTop: '3px', textAlign: 'left' } }} control={
								<Checkbox
									checked={addAlias}
									disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
									onChange={changeAddAliasHandler}
									size='small'
								/>
							} label={translate('checkbox_useCacheAlias')} />
						</div>
						:
						<FormControl margin='dense'>
							<TextField
								label={translate('input_depth')}
								variant="outlined"
								type='number'
								value={depth}
								onChange={(e) => setDepth(+e.target.value)}
								onBlur={changeDepthHandler}
								error={depth < 1 || depth > 10}
								disabled={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
								InputProps={{
									style: {
										height: 33,
										fontSize: 13,
										color: colorPrimary,
									},
									inputProps: { min: 1, max: 10 },
								}}
								InputLabelProps={{
									style: {
										fontSize: 13,
									},
								}}
								sx={{ '.MuiInputLabel-root[data-shrink="false"]': { top: -8 } }}
							/>
						</FormControl>
					}
				</>
			}

			{service !== 'qas-chat' &&
				<div className={styles.additionally} onClick={() => setShowAdditionalSettings(prev => !prev)}>
					{translate('link_conditionsRun')}
				</div>
			}

			{showAdditionalSettings && service !== 'qas-chat' &&
				<>
					{'conditions' in action && action.conditions?.map((conditionDataBlock, conditionBlockIdx, arrayConditionsData) => (
						// блоки ИЛИ
						<ConditionRunBlock
							conditionDataBlock={conditionDataBlock}
							conditionBlockIdx={conditionBlockIdx}
							isDisable={((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && !isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && !isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && !isAccess(SES.ENDPOINT_EDIT)) || activeRobotVersion !== 'draft'}
							isAvailable={(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft'}
							changeFlg={changeFlg}
							setChangeFlg={setChangeFlg}
							conditionFor={{
								for: 'action',
								itsAction: actionFor.for,
								actionEvent: (actionFor.for === 'robotEvent' || actionFor.for === 'dataElement') ? actionFor.event : actionFor.for === 'robotException' ? actionFor.exception : undefined,
								channel,
								channelIdx: idx,
							}}
							addConditionRunHandler={addConditionRunActionHandler}
							arrayConditionsData={arrayConditionsData}
							key={conditionDataBlock.toString() + conditionBlockIdx}
						/>
					))}
					{(!(action as Exclude<ActionVariantType, IActionInternalChat>).conditions || (action as Exclude<ActionVariantType, IActionInternalChat>)?.conditions?.length === 0) ?
						(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
						// форма добавления условия
						<fieldset className={styles.categoryDataBlock}>
							<legend>{translate('title_conditions')}</legend>
							<FormAddingConditionRunAction
								channel={channel}
								channelIdx={idx}
								conditionBlockIdx={(action as Exclude<ActionVariantType, IActionInternalChat>).conditions?.length || 0}
								changeFlg={changeFlg}
								setChangeFlg={setChangeFlg}
								actionFor={actionFor}
							/>
						</fieldset>
						:
						(((actionFor.for === 'robotEvent' || actionFor.for === 'robotException') && isAccess(SES.ROBOT_EDIT)) || (actionFor.for === 'dataElement' && isAccess(SES.DATA_EDIT)) || ((actionFor.for === 'endpoint' || actionFor.for === 'endpointEntry') && isAccess(SES.ENDPOINT_EDIT))) && activeRobotVersion === 'draft' &&
						// разделитель-кнопка ИЛИ
						<div className={styles.categoryDataBlockLineButton}>
							<span onClick={() => addConditionRunActionHandler(('conditions' in action && action.conditions?.length) || 0)}>{translate('title_or').toUpperCase()}</span>
						</div>
					}
				</>
			}
		</div>
	);
};

export default ActionEvent;
